import { useAtom } from 'jotai';
import styled from 'styled-components';
import { existingExprAtom } from '../../../Inst/state/inst_meta';
import Loading from '../../../components/Loading/LoadingDots';
import BooleanExpressionIdentity from './BooleanExpressionIdentity';

const Styled = styled.div`
  margin-left: -20px;

  > ul:first-child {
    > li:first-child {
      > h5 {
        display: none;
      }
    }
  }
`;

const BooleanExpressionEntrypoint: React.FC = () => {
  const [booleanExpression] = useAtom(existingExprAtom);

  if (!booleanExpression) return <Loading />;

  return (
    <Styled>
      <BooleanExpressionIdentity $expr={booleanExpression} />
    </Styled>
  );
};

export default BooleanExpressionEntrypoint;
