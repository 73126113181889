import { useAtom } from 'jotai';
import styled from 'styled-components';
import Icon from '../../../components/icons/Icon';
import { SimulatedPrediction } from '../../../protos/recommender/meta_pb';
import { downloadCSV } from '../../../Settings/ui/download_csv';
import { expiringBeforeAtom, sortedExpiringInstsAtom } from '../../state/expiring_insts';

const Wrapper = styled.div`
  font-size: 1.4rem;

  &:hover {
    button {
      color: var(--color-focus);
    }
  }
`;

const ExpiringInstsDownloadCSV: React.FC = () => {
  const [insts] = useAtom(sortedExpiringInstsAtom);
  const [expiringBefore] = useAtom(expiringBeforeAtom);

  function createCSV() {
    const headers = [
      'Predicted At',
      'ID',
      'Name',
      'Start',
      'End',
      'Delivered Impressions',
      'Booked Impressions',
      'Projected Total Reach',
      '110% Booked Impressions',
      'Projected Total Reach with 110% Booked Impressions',
      '125% Booked Impressions',
      'Projected Total Reach with 125% Booked Impressions',
      '150% Booked Impressions',
      'Projected Total Reach with 150% Booked Impressions',
      '175% Booked Impressions',
      'Projected Total Reach with 175% Booked Impressions',
      '200% Booked Impressions',
      'Projected Total Reach with 200% Booked Impressions',
      'Org',
      'Advertiser',
      'Agency',
      'Station'
    ];

    function formatPredictedReach(
      predictions: SimulatedPrediction[],
      additionalImpressions: number
    ): string {
      const reach =
        predictions.find(
          (p) => parseFloat(p.additionalImpressions.toFixed(2)) === additionalImpressions
        )?.reach ?? 0;

      // If greater than 1M, round to nearest 100k
      if (reach > 1_000_000) {
        return (Math.round(reach / 100_000) * 100_000).toString();
      }

      // If greater than 100k, round to nearest 10k
      if (reach > 100_000) {
        return (Math.round(reach / 10_000) * 10_000).toString();
      }

      // If greater than 10k, round to nearest 1k
      if (reach > 10_000) {
        return (Math.round(reach / 1_000) * 1_000).toString();
      }

      // round to nearest hundred
      return (Math.round(reach / 100) * 100).toString();
    }

    const rows = insts.reduce((acc, inst) => {
      acc.push([
        inst.predictedAt?.toDate().toISOString() ?? '',
        inst.id,
        inst.name,
        inst.start?.toDate().toISOString() ?? '',
        inst.end?.toDate().toISOString() ?? '',
        inst.deliveredImpressions.toString(),
        inst.bookedImpressions.toString(),
        formatPredictedReach(inst.predictions, 0),
        (inst.bookedImpressions * 1.1).toFixed(0),
        formatPredictedReach(inst.predictions, 0.1),
        (inst.bookedImpressions * 1.25).toFixed(0),
        formatPredictedReach(inst.predictions, 0.25),
        (inst.bookedImpressions * 1.5).toFixed(0),
        formatPredictedReach(inst.predictions, 0.5),
        (inst.bookedImpressions * 1.75).toFixed(0),
        formatPredictedReach(inst.predictions, 0.75),
        (inst.bookedImpressions * 2).toFixed(0),
        formatPredictedReach(inst.predictions, 1),
        inst.clientName,
        inst.advertiser,
        inst.agency,
        inst.station
      ]);
      return acc;
    }, [] as string[][]);

    return downloadCSV(
      headers,
      rows,
      `lines-expiring-before-${expiringBefore.toLocaleDateString().replaceAll(' ', '-')}`
    );
  }

  return (
    <Wrapper>
      <button title="Download CSV" onClick={createCSV}>
        <Icon name="download" />
      </button>
    </Wrapper>
  );
};

export default ExpiringInstsDownloadCSV;
