import { Timestamp } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import { atom } from 'jotai';
import withClient from '../../proto/with_client';
import { Graph } from '../../protos/graph/graph_pb';
import { Predict } from '../../protos/recommender/predict_connect';
import { InstPrediction } from '../../protos/recommender/predict_pb';
import {
  instIdAtom,
  currentTimeAtom,
  existingBookedImpressionsAtom,
  existingExprAtom,
  existingReachAtom,
  fulfillmentPercentageAtom,
  predictUsingDataBeforeTimeAtom,
  wipBookedImpressionsAtom,
  wipExprAtom
} from './inst_meta';

const client = withClient(Predict);
export const loadingAtom = atom<boolean>(false);
export const impressionsAtCurrentTimeAtom = atom<number | null>(null);
export const graphsAtom = atom<Graph[] | undefined>(undefined);
export const additionalReachAtom = atom<number>(0);

export const maxCpmExistingAtom = atom<number>(0);
export const minCpmExistingAtom = atom<number>(0);
export const maxCpmWipAtom = atom<number>(0);
export const minCpmWipAtom = atom<number>(0);

let timeout: ReturnType<typeof setTimeout> | null = null;
const debounce = <F>(func: (...args: unknown[]) => Promise<F>, waitFor: number) => {
  const debounced = (...args: unknown[]) => {
    return new Promise<F>((resolve) => {
      if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => func(...args).then(resolve), waitFor);
    });
  };

  return debounced as (...args: unknown[]) => Promise<F>;
};

export const debouncedRepredict = (cb: () => Promise<void>) => debounce(cb, 300);

export const repredictAtom = atom(null, async (get, set) => {
  const instId = get(instIdAtom);
  const booleanExpression = get(wipExprAtom);
  const instExpression = get(existingExprAtom);
  const time = get(currentTimeAtom);
  const dataBeforeTime = get(predictUsingDataBeforeTimeAtom);
  const currentTime = Timestamp.fromDate(time ?? new Date());
  const predictUsingDataBefore = Timestamp.fromDate(dataBeforeTime ?? new Date());
  const expressionsMatch = booleanExpression?.equals(instExpression) ?? false;
  const bookedImpressions =
    get(wipBookedImpressionsAtom) ?? get(existingBookedImpressionsAtom) ?? 0;
  set(loadingAtom, true);

  try {
    let resp: InstPrediction;
    if (expressionsMatch || !booleanExpression) {
      resp = await client.byInst({
        bookedImpressions,
        currentTime,
        instId,
        predictUsingDataBefore
      });
    } else {
      resp = await client.byBooleanExpression({
        bookedImpressions,
        booleanExpression,
        currentTime,
        instId,
        predictUsingDataBefore
      });
    }

    set(existingReachAtom, resp.totalReach);
    set(fulfillmentPercentageAtom, resp.fulfillmentPercentage);
    set(graphsAtom, resp.graphs);
    set(impressionsAtCurrentTimeAtom, resp.impressionsAtCurrentTime);
    set(additionalReachAtom, resp.additionalReach);

    set(maxCpmExistingAtom, resp.existingPricing?.max ?? 0);
    set(minCpmExistingAtom, resp.existingPricing?.min ?? 0);
    set(maxCpmWipAtom, resp.wipPricing?.max ?? 0);
    set(minCpmWipAtom, resp.wipPricing?.min ?? 0);
  } catch (e) {
    if (e instanceof ConnectError) {
      if (e.message.includes('cancelled duplicate request')) return;
    }
    console.warn(e);
  } finally {
    set(loadingAtom, false);
  }
});
