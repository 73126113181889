import * as d3 from 'd3';
import humanizeDuration from 'humanize-duration';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Layout/Modal';
import { LoadingPadded } from '../../../components/Loading/LoadingDots';
import { ExpiringInst, SimulatedPrediction } from '../../../protos/recommender/meta_pb';
import {
  allExpiringInstsAtom,
  expiringInstsAtom,
  expiringLoadingAtom,
  sortByAtom,
  sortedExpiringInstsAtom,
  sortOrderAtom
} from '../../state/expiring_insts';
import { loadingAtom, repredictAtom } from '../../state/inst';
import { instIdAtom, instLoadingAtom, refetchAtom } from '../../state/inst_meta';
import InstBookedImpressions from '../InstBookedImpressions';
import { InstGraphsSection } from '../InstCard';
import InstStatistics from '../InstStatistics';
import ExpiringInstsTableHeader from './ExpiringInstsTableHeader';
import InstNameCell from './InstNameCell';
import TableCell from './TableCell';
import Tooltip from './Tooltip';

const TableRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;
  cursor: pointer;

  &:nth-child(even) {
    background: rgba(var(--color-border-rgb), 0.4);
  }

  &:hover {
    background: rgba(var(--color-focus-rgb), 0.2);
  }
`;

const ModalHeader = styled.header`
  h3 {
    margin-bottom: 2rem;
    text-transform: none;
  }

  a {
    color: var(--color-focus);
  }
`;

function ExpiringInstsTable() {
  const [insts] = useAtom(sortedExpiringInstsAtom);
  const [, fetchInsts] = useAtom(expiringInstsAtom);
  const [allInsts] = useAtom(allExpiringInstsAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [loading] = useAtom(loadingAtom);
  const [loadingMeta] = useAtom(instLoadingAtom);
  const [instId, setInstId] = useAtom(instIdAtom);
  const [, refetchMeta] = useAtom(refetchAtom);
  const [, repredict] = useAtom(repredictAtom);
  const [selectedName, setSelectedName] = useState<string>('');
  const [loadingTable] = useAtom(expiringLoadingAtom);
  const [sortOrder] = useAtom(sortOrderAtom);
  const [sortBy] = useAtom(sortByAtom);

  const handleRowClick = (inst: ExpiringInst) => {
    setInstId(inst.id);
    setSelectedName(inst.name);
    refetchMeta(inst.id).then(repredict);
    setIsOpen(true);
  };

  useEffect(() => {
    if (allInsts.length) return;
    fetchInsts();
  }, [allInsts, fetchInsts, sortBy, sortOrder]);

  return (
    <div className="table">
      <ExpiringInstsTableHeader />
      {loadingTable ? (
        <LoadingPadded />
      ) : (
        <div className="table-body">
          {insts.map((inst) => (
            <TableRow
              id={inst.id}
              key={inst.id}
              className="table-row"
              onClick={() => handleRowClick(inst)}>
              <InstNameCell inst={inst} />
              <TableCell>{d3.format('.2s')(inst.bookedImpressions)}</TableCell>
              <TableCell>
                {d3.format('.1%')(inst.deliveredImpressions / inst.bookedImpressions)}
              </TableCell>
              <TableCell title={`Start: ${inst.start?.toDate()}\nEnd: ${inst.end?.toDate()}`}>
                {humanizeDuration(new Date().getTime() - (inst.end?.toDate().getTime() ?? 0), {
                  largest: 1
                })}
              </TableCell>
              <TableCellFormatter
                additionalImpressions={0}
                bookedImpressions={inst.bookedImpressions}
                predictions={inst.predictions}
              />
              <TableCellFormatter
                additionalImpressions={0.1}
                bookedImpressions={inst.bookedImpressions}
                predictions={inst.predictions}
              />
              <TableCellFormatter
                additionalImpressions={0.25}
                bookedImpressions={inst.bookedImpressions}
                predictions={inst.predictions}
              />
              <TableCellFormatter
                additionalImpressions={0.5}
                bookedImpressions={inst.bookedImpressions}
                predictions={inst.predictions}
              />
              <TableCellFormatter
                additionalImpressions={0.75}
                bookedImpressions={inst.bookedImpressions}
                predictions={inst.predictions}
              />
              <TableCellFormatter
                additionalImpressions={1}
                bookedImpressions={inst.bookedImpressions}
                predictions={inst.predictions}
              />
            </TableRow>
          ))}
        </div>
      )}
      <Modal pinRight={true} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {loading || loadingMeta ? (
          <LoadingPadded />
        ) : (
          <>
            <ModalHeader>
              <h3>
                <a href={`/?instId=${instId}`} target="_blank" rel="noreferrer">
                  {selectedName}
                </a>
              </h3>
            </ModalHeader>
            <div>
              <InstGraphsSection />
              <InstStatistics />
              <InstBookedImpressions />
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

const TableCellFormatter: React.FC<{
  additionalImpressions: number;
  bookedImpressions: number;
  predictions: SimulatedPrediction[];
}> = ({ additionalImpressions, bookedImpressions, predictions }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const prediction = predictions.find(
    (p) => parseFloat(p.additionalImpressions.toFixed(2)) === additionalImpressions
  );
  const baseReach = predictions.find((p) => p.additionalImpressions === 0)?.reach ?? 0;
  const value =
    additionalImpressions === 0 ? prediction?.reach : (prediction?.reach ?? 0) - baseReach;
  const renderAs =
    (value ?? 0) === 0
      ? 0
      : (additionalImpressions === 0 ? '' : '+') + d3.format('.2s')(value ?? 0);
  const predictionIsNan = isNaN(prediction?.reachMarginOfError ?? 0);
  const hasMarginOfError = (prediction?.reachMarginOfError ?? 0) > 0;

  return (
    <TableCell
      onMouseEnter={(e) => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      title={(value ?? 0).toString()}>
      {renderAs}
      {showTooltip && !predictionIsNan && hasMarginOfError && (
        <Tooltip $left="0">
          <small>
            <strong>Total Impressions</strong>
            {d3.format(',')(Math.round(bookedImpressions * (1 + additionalImpressions)))}
          </small>
          <small>
            <strong>Margin of Error</strong> +/-
            {d3.format('.1%')(prediction?.reachMarginOfError ?? 0)}
          </small>
        </Tooltip>
      )}
    </TableCell>
  );
};

export default ExpiringInstsTable;
