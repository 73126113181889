import styled from 'styled-components';
import ExpiringInstsDownloadCSV from './ExpiringInstsDownloadCSV';
import ExpiringInstsFilter from './ExpiringInstsFilter';
import ExpiringInstsMinimumImpressionsFilter from './ExpiringInstsMinimumImpressionsFilter';
import ExpiringInstsPoliticalFilter from './ExpiringInstsPoliticalFilter';
import ExpiringInstsTable from './ExpiringInstsTable';
import ExpiringInstsWithinFilter from './ExpiringInstsWithinFilter';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;

  > input {
    flex: 1 0 0;
  }

  > div {
    padding: 0 1rem;
  }
`;

const ExpiringInsts: React.FC = () => {
  return (
    <div>
      <Header>
        <ExpiringInstsFilter />
        <ExpiringInstsMinimumImpressionsFilter />
        <ExpiringInstsPoliticalFilter />
        <ExpiringInstsWithinFilter />
        <ExpiringInstsDownloadCSV />
      </Header>

      <ExpiringInstsTable />
    </div>
  );
};

export default ExpiringInsts;
