import { useAtom } from 'jotai';
import { useEffect } from 'react';
import styled from 'styled-components';
import { expiringInstsAtom, expiringMinimumImpressionsAtom } from '../../state/expiring_insts';

const Wrapper = styled.div`
  text-align: right;

  div {
    font-weight: bold;
  }
`;

const Styled = styled.input`
  text-align: right;
  border-radius: 20px;
  background: transparent;
  border: 0;
  max-width: 74px;
  font-weight: bold;
  padding: 0;
  border-radius: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border-bottom: 1px solid var(--color-focus);
  }
`;

const ExpiringInstsMinimumImpressionsFilter: React.FC = () => {
  const [value, setValue] = useAtom(expiringMinimumImpressionsAtom);
  const [, fetchInsts] = useAtom(expiringInstsAtom);

  useEffect(() => {
    fetchInsts();
  }, [value, fetchInsts]);

  return (
    <Wrapper>
      At least{' '}
      <Styled
        type="number"
        value={value}
        placeholder="1"
        min={1}
        max={10_000_000}
        step={1_000}
        onChange={(e) => setValue(parseInt(e.target.value))}
      />
      <br />
      <strong>impressions</strong>
    </Wrapper>
  );
};

export default ExpiringInstsMinimumImpressionsFilter;
