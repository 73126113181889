import { useAtom } from 'jotai';
import { useEffect } from 'react';
import styled from 'styled-components';
import { expiringInstsAtom, expiringInstsIsPoliticalAtom } from '../../state/expiring_insts';

const Styled = styled.button`
  text-align: right;
`;

const ExpiringInstsPoliticalFilter: React.FC = () => {
  const [checked, setChecked] = useAtom(expiringInstsIsPoliticalAtom);
  const [, fetchInsts] = useAtom(expiringInstsAtom);

  useEffect(() => {
    fetchInsts();
  }, [checked, fetchInsts]);

  return (
    <Styled onClick={(e) => setChecked(!checked)}>
      Show
      <strong>
        {checked ? ' ' : ' No '}
        Political
      </strong>
      <br />
      Lines
    </Styled>
  );
};

export default ExpiringInstsPoliticalFilter;
