import { useAtom } from 'jotai';
import { useEffect } from 'react';
import styled from 'styled-components';
import { repredictAtom } from '../../state/inst';
import { instIdAtom, refetchAtom } from '../../state/inst_meta';
import OmniSearch from './OmniSearch';

const Styled = styled.div`
  display: flex;
  justify-content: space-between;

  fieldset {
    border: none;
  }

  > fieldset,
  > form {
    flex: 1 0 0;
    padding: 0 0.3rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const InstSearch = () => {
  const [instId, setInstId] = useAtom(instIdAtom);
  const [, repredict] = useAtom(repredictAtom);
  const [, refetch] = useAtom(refetchAtom);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const instIdParam = params.get('instId');
    if (instIdParam) {
      setInstId(instIdParam);
    }
  }, [setInstId]);

  useEffect(() => {
    if (!instId.length) return;
    refetch(instId).then(repredict);
  }, [instId, refetch, repredict]);

  return (
    <Styled>
      <OmniSearch />
    </Styled>
  );
};

export default InstSearch;
