import * as d3 from 'd3';
import { PrimitiveAtom, useAtom } from 'jotai';
import React from 'react';
import styled from 'styled-components';
import {
  additionalReachAtom,
  maxCpmExistingAtom,
  maxCpmWipAtom,
  minCpmExistingAtom,
  minCpmWipAtom
} from '../state/inst';
import { existingReachAtom, fulfillmentPercentageAtom } from '../state/inst_meta';

const Container = styled.section`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem 0 0;
`;

const Wrapper = styled.section`
  justify-content: space-evenly;
  flex: 1 0 0;
`;

const Styled = styled.article<{ $highlighted?: boolean }>`
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: ${(props) => (props.$highlighted ? '1rem' : '0')};

  h2 {
    font-size: 3rem;
    line-height: 1;
  }

  h3 {
    font-size: 1.5rem;
  }

  strong {
    color: var(--color-recorded);
    font-size: 0.75rem;
    margin: 0.2rem 0 0;
    text-transform: uppercase;
    display: block;
  }

  .highlight {
    color: var(--color-assistance);
  }

  small {
    color: var(--color-border);
  }
`;

const CpmStatistics: React.FC<{
  $maxAtom: PrimitiveAtom<number>;
  $minAtom: PrimitiveAtom<number>;
  $highlighted?: boolean;
}> = ({ $maxAtom, $minAtom, $highlighted }) => {
  const [max] = useAtom($maxAtom);
  const [min] = useAtom($minAtom);

  function format(value: number): string {
    if (value === 0) return '0';
    return d3
      .format('($.2f')(value / 100)
      .replace('.00', '');
  }

  // This edge case can happen when MDM segments are swapped with Liveramp segments
  const lowValue = min === 0 ? max : Math.min(max, min);
  const showBoth = lowValue !== 0 && max !== 0 && lowValue !== max && max > lowValue;

  return (
    <Styled $highlighted={$highlighted}>
      <h3 className={$highlighted ? 'highlight' : undefined}>
        {showBoth && (
          <>
            {format(lowValue)} - {format(max)}
          </>
        )}
        {!showBoth && format(lowValue)}
        <strong>{$highlighted ? 'New CPM' : 'Current CPM'}</strong>
      </h3>
    </Styled>
  );
};

const ReachStatistics: React.FC = () => {
  const [existingReach] = useAtom(existingReachAtom);
  const [additionalReach] = useAtom(additionalReachAtom);
  const [fulfillmentPercentage] = useAtom(fulfillmentPercentageAtom);

  function format(value: number): string {
    if (value === 0) return '0';
    if (value < 1) return d3.format('.1f')(value);
    return d3.format('.2s')(value);
  }

  if (additionalReach !== 0) {
    return (
      <Styled>
        <h2 className="highlight">
          <abbr title={(additionalReach * fulfillmentPercentage + existingReach).toFixed(0)}>
            {format(additionalReach * fulfillmentPercentage + existingReach)}
          </abbr>
          <strong>New Reach</strong>
        </h2>
        <h2>
          <abbr title={existingReach.toFixed(0)}>{format(existingReach)}</abbr>
          <strong>Current Reach</strong>
        </h2>
      </Styled>
    );
  }

  return (
    <Styled>
      <h2>
        {format(existingReach)}
        <strong>Current Reach</strong>
      </h2>
    </Styled>
  );
};

const InstStatistics: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <ReachStatistics />
      </Wrapper>
      <Wrapper>
        <CpmStatistics $maxAtom={maxCpmWipAtom} $minAtom={minCpmWipAtom} $highlighted={true} />
        <CpmStatistics
          $maxAtom={maxCpmExistingAtom}
          $minAtom={minCpmExistingAtom}
          $highlighted={false}
        />
      </Wrapper>
    </Container>
  );
};

export default InstStatistics;
