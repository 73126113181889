import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { expiringBeforeAtom, expiringInstsAtom } from '../../state/expiring_insts';

const Wrapper = styled.div`
  text-align: right;

  div {
    font-weight: bold;
  }
`;

const Styled = styled.input`
  text-align: right;
  border-radius: 20px;
  background: transparent;
  border: 0;
  max-width: 50px;
  font-weight: bold;
  padding: 0;
  border-radius: 0;

  &:focus {
    border-bottom: 1px solid var(--color-focus);
  }
`;

const ExpiringInstsWithinFilter: React.FC = () => {
  const [fieldValue, setFieldValue] = useState(7);
  const [, setValue] = useAtom(expiringBeforeAtom);
  const [, fetchInsts] = useAtom(expiringInstsAtom);

  useEffect(() => {
    const now = new Date();
    now.setDate(now.getDate() + fieldValue);
    setValue(now);
    fetchInsts();
  }, [fieldValue, setValue, fetchInsts]);

  return (
    <Wrapper>
      Lines Ending in
      <div>
        <Styled
          type="number"
          value={fieldValue}
          placeholder="7"
          min={1}
          max={99}
          onChange={(e) => setFieldValue(parseInt(e.target.value))}
        />{' '}
        days
      </div>
    </Wrapper>
  );
};

export default ExpiringInstsWithinFilter;
