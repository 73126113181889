import * as Plot from '@observablehq/plot';
import { useAtom } from 'jotai';
import protoToMarks from '../../components/Graph/data/proto_to_marks';
import BaseGraph from '../../components/Graph/ui/BaseGraph';
import { Graph } from '../../protos/graph/graph_pb';
import { impressionsAtCurrentTimeAtom } from '../state/inst';
import {
  currentTimeAtom,
  existingBookedImpressionsAtom,
  wipBookedImpressionsAtom
} from '../state/inst_meta';

interface ContentProps {
  data: Graph;
}

const InstGraph: React.FC<ContentProps> = ({ data }) => {
  const marks = protoToMarks(data);
  const [currentTime] = useAtom(currentTimeAtom);
  const [impressionsAtCurrentTime] = useAtom(impressionsAtCurrentTimeAtom);
  const [wipBookedImpressions] = useAtom(wipBookedImpressionsAtom);
  const [existingBookedImpressions] = useAtom(existingBookedImpressionsAtom);

  if (data.feeds.every((feed) => feed.renderAs.case === 'line' || feed.renderAs.case === 'area')) {
    if (data.feeds.every((feed) => feed.feed?.feed?.case === 'numeric')) {
      marks.push(Plot.ruleX([impressionsAtCurrentTime], { stroke: 'var(--color-alternate)' }));

      if (wipBookedImpressions !== existingBookedImpressions) {
        marks.push(Plot.ruleX([existingBookedImpressions], { stroke: 'var(--color-border)' }));
      }
    } else {
      marks.push(Plot.ruleX([currentTime], { stroke: 'var(--color-alternate)' }));
    }
  }

  return <BaseGraph meta={data.meta!} marks={marks} />;
};

export default InstGraph;
