import { atom } from 'jotai';
import { existingSegmentByIdAtom, segmentByIdAtom } from '../../Segments/state/segments';
import withClient from '../../proto/with_client';
import { BooleanExpression } from '../../protos/recommender/boolean_expression_pb';
import { Meta } from '../../protos/recommender/meta_connect';
import { graphsAtom } from './inst';

const client = withClient(Meta);

export const instIdAtom = atom<string>('');
export const instLoadingAtom = atom<boolean>(false);
export const currentTimeAtom = atom<Date>(new Date());
export const predictUsingDataBeforeTimeAtom = atom<Date>(new Date());
export const instStartAtom = atom<Date | null>(null);
export const instEndAtom = atom<Date | null>(null);
export const instNameAtom = atom<string | null>(null);
export const existingBookedImpressionsAtom = atom<number>(0);
export const existingExprAtom = atom<BooleanExpression | undefined>(undefined);
export const fulfillmentPercentageAtom = atom<number>(0);
export const existingReachAtom = atom<number>(0);

export const wipExprAtom = atom<BooleanExpression | undefined>(undefined);
export const wipBookedImpressionsAtom = atom<number | undefined>(undefined);

export const refetchAtom = atom(null, async (get, set, id: string) => {
  set(instIdAtom, id);
  set(instLoadingAtom, true);
  set(currentTimeAtom, new Date());
  set(instStartAtom, null);
  set(instEndAtom, null);
  set(instNameAtom, null);
  set(existingBookedImpressionsAtom, 0);
  set(existingExprAtom, undefined);
  set(fulfillmentPercentageAtom, 0);
  set(predictUsingDataBeforeTimeAtom, new Date());
  set(existingReachAtom, 0);

  set(wipExprAtom, undefined);

  set(existingSegmentByIdAtom, {});
  set(segmentByIdAtom, {});
  set(segmentByIdAtom, {});

  set(graphsAtom, []);

  try {
    const resp = await client.inst({ instId: id });
    set(instNameAtom, resp.name);
    const start = resp.start?.toDate() ?? new Date();
    set(instStartAtom, start);
    const end = resp.end?.toDate() ?? new Date();
    set(instEndAtom, end);
    set(fulfillmentPercentageAtom, resp.fulfillmentPercentage);
    set(existingBookedImpressionsAtom, resp.bookedImpressions);
    set(wipBookedImpressionsAtom, resp.bookedImpressions);

    // set current time to 3/4s of the way through the inst
    if (new Date().getTime() < start.getTime()) {
      set(currentTimeAtom, start);
    } else if (new Date().getTime() < end.getTime()) {
      set(currentTimeAtom, new Date());
    } else {
      set(currentTimeAtom, new Date(start.getTime() + (end.getTime() - start.getTime()) * 0.75));
    }
    set(existingReachAtom, resp.totalReach);
    set(predictUsingDataBeforeTimeAtom, end);

    const segmentsResp = await client.segments({
      instId: id,
      existingExpr: resp.expr,
      wipExpr: resp.expr
    });

    const byId = get(segmentByIdAtom);
    const existingById = get(existingSegmentByIdAtom);
    for (const s of segmentsResp.segments) {
      byId[s.id] = s;
      existingById[s.id] = true;
    }
    set(segmentByIdAtom, byId);
    set(existingSegmentByIdAtom, existingById);

    if (resp.expr) set(existingExprAtom, resp.expr);
    if (resp.expr) set(wipExprAtom, resp.expr);
  } catch (e) {
    console.warn(e);
  } finally {
    set(instLoadingAtom, false);
  }
});
