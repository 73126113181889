import * as d3 from 'd3';
import { useAtom } from 'jotai';
import React from 'react';
import styled from 'styled-components';
import { fulfillmentPercentageAtom } from '../../Inst/state/inst_meta';
import { segmentByIdAtom } from '../state/segments';

interface Props {
  $existing: boolean;
  $selected: boolean;
}

const Styled = styled.div<Props>`
  --local-color: ${(props) =>
    props.$existing ? 'var(--color-border-rgb)' : 'var(--color-assistance-rgb)'};

  align-items: center;
  background-color: ${(props) =>
    props.$selected
      ? props.$existing
        ? 'rgba(var(--color-border-rgb), 0.3)'
        : 'rgba(var(--color-assistance-rgb), 0.04)'
      : 'inherit'};
  border-color: rgba(
    var(--local-color),
    ${(props) => (props.$existing || props.$selected ? 1 : 0.4)}
  );
  border-radius: 20px;
  border-style: ${(props) => (props.$selected && !props.$existing ? 'dashed' : 'solid')};
  border-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  margin: 0.2rem 0;
  padding: 0.6rem 0.5rem;
  transition: 0.4s box-shadow;
  width: 100%;

  &:hover {
    // Cheap way of hiding the highlight on active item
    box-shadow: none;
  }

  small {
    color: var(--color-focus);
    display: block;
    font-weight: bold;
    flex-basis: 60px;
    padding-left: 3px;
    text-align: right;
    font-size: 0.7rem;
  }

  em {
    font-style: normal;
    display: block;
    text-align: center;
  }
`;

const SegmentIdentity: React.FC<{ $id: string; $existing: boolean; $selected?: boolean }> = ({
  $existing,
  $id,
  $selected = false
}) => {
  const [percentage] = useAtom(fulfillmentPercentageAtom);
  const [segmentById] = useAtom(segmentByIdAtom);
  const segment = segmentById[$id];

  function format(value: number): string {
    if (value === 0) return '0';
    if (value < 1 && value > 0) return d3.format('.1f')(value);
    return d3.format('.2s')(value);
  }

  // const reach = (wipReachById[$id] ?? segment.reach) * percentage;
  const reach = segment.reach * percentage;

  const id = segment.swapTo ? `${segment.id}---BECOMES---${segment.swapTo.id}` : segment.id;

  return (
    <Styled $existing={$existing} $selected={$selected} id={id}>
      {!!segment.swapTo && <div title={`Swaps to "${segment.swapTo.name}"`}>{segment.name}</div>}
      {!segment.swapTo && <span>{segment.name}</span>}
      <small>{($existing ? '' : reach > 0 ? '+' : '') + format(reach)}</small>
    </Styled>
  );
};

export default SegmentIdentity;
