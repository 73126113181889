import { useAtom } from 'jotai';
import React from 'react';
import Card from '../../components/Card';
import Loading, { LoadingPadded } from '../../components/Loading/LoadingDots';
import { graphsAtom, loadingAtom } from '../state/inst';
import { instNameAtom } from '../state/inst_meta';
import InstBookedImpressions from './InstBookedImpressions';
import InstCurrentTime from './InstCurrentTime';
import InstGraphs from './InstGraphs';
import InstPredictUsingDataBefore from './InstPredictUsingDataBefore';
import InstStatistics from './InstStatistics';

export const InstGraphsSection: React.FC = () => {
  const [predictions] = useAtom(graphsAtom);

  if (!predictions?.length) {
    return <LoadingPadded />;
  }

  return <InstGraphs graphs={predictions} />;
};

const InstCard: React.FC = () => {
  const [name] = useAtom(instNameAtom);
  const [loading] = useAtom(loadingAtom);

  return (
    <Card
      name={name ?? 'Inst'}
      type="poly"
      title={
        <h3>
          <span>{name}</span> {loading && <Loading />}
        </h3>
      }>
      <InstGraphsSection />
      <InstStatistics />
      <InstBookedImpressions />
      <InstCurrentTime />
      <InstPredictUsingDataBefore />
    </Card>
  );
};

export default InstCard;
