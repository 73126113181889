import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HighContrastToggle from './HighContrastToggle';

const Styled = styled.div`
  text-align: right;

  a,
  button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    margin: 1rem auto 0;

    &:after {
      content: '|';
      display: inline-block;
      padding: 0 10px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`;

const Settings: React.FC = () => {
  const location = useLocation();
  let linkUrl = '/dashboard';
  let linkText = 'Dashboard';
  if (location.pathname === '/dashboard') {
    linkUrl = '/';
    linkText = 'Home';
  }
  return (
    <Styled>
      <a href={linkUrl}>{linkText}</a>
      <HighContrastToggle />
    </Styled>
  );
};

export default Settings;
