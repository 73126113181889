import styled from 'styled-components';

const TableCell = styled.div`
  flex: 0 1 100px;
  text-align: center;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &:nth-child(odd) {
    background: rgba(var(--color-border-rgb), 0.4);
  }

  &:first-child {
    flex: 1 0 0;
    text-align: left;
    max-width: 475px;
    background: none;
    word-break: break-all;
  }

  small {
    font-size: 0.7em;
    display: block;
  }
`;

export default TableCell;
