import { useAtom } from 'jotai';
import styled from 'styled-components';
import { SortBy, sortByAtom, SortOrder, sortOrderAtom } from '../../state/expiring_insts';

const TableHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  background: var(--color-alternate);
  border-radius: 20px;
`;

const TableHeader = styled.button`
  flex: 0 1 100px;

  &:first-child {
    flex: 1 0 0;
  }

  small {
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7em;
  }

  span {
    font-size: 0.7rem;
    padding-left: 0.2rem;
    display: inline-block;
    vertical-align: middle;
  }
`;

const ExpiringInstsTableHeader: React.FC = () => {
  const [activeSort, setSortBy] = useAtom(sortByAtom);
  const [sortOrder, setSortOrder] = useAtom(sortOrderAtom);

  const headers = {
    Line: SortBy.Name,
    Booked: SortBy.BookedImpressions,
    Delivered: SortBy.DeliveredImpressions,
    'Expires In': SortBy.ExpiresIn,
    Reach: SortBy.Reach,
    'Reach w/ +10%': SortBy.Reach10,
    'Reach w/ +25%': SortBy.Reach25,
    'Reach w/ +50%': SortBy.Reach50,
    'Reach w/ +75%': SortBy.Reach75,
    'Reach w/ +100%': SortBy.Reach100
  };

  function changeSortBy(sortBy: SortBy) {
    if (activeSort === sortBy) {
      setSortOrder(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc);
      return;
    }

    setSortOrder(SortOrder.Desc);
    setSortBy(sortBy);
  }

  return (
    <TableHeaderRow className="table-header">
      {Object.entries(headers).map(([label, sortBy]) => (
        <TableHeader key={label} className="table-header-cell" onClick={() => changeSortBy(sortBy)}>
          {label.includes('Reach w/') ? (
            <>
              <small>Reach w/</small>
              {label.replaceAll('Reach w/', '')}
            </>
          ) : (
            label
          )}
          <span>{activeSort === sortBy && (sortOrder === SortOrder.Asc ? '▲' : '▼')}</span>
        </TableHeader>
      ))}
    </TableHeaderRow>
  );
};

export default ExpiringInstsTableHeader;
