import styled from 'styled-components';

const Tooltip = styled.div<{ $left: string }>`
  position: absolute;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 0.3rem 0.5rem;
  left: ${(props) => props.$left};
  border-radius: 5px;
  z-index: 1;
  bottom: 100%;

  strong {
    text-transform: uppercase;
    display: inline-block;
    padding-right: 0.5rem;
    font-size: 0.7em;
  }
`;

export default Tooltip;
