export function downloadCSV(headers: string[], rows: string[][], filename: string) {
  const csvString = rows.reduce(
    (acc, row) => {
      const sanitizedRow = row.map((cell) => {
        if (cell.includes(',')) return `"${cell}"`;
        return cell;
      });
      return acc + sanitizedRow.join(',') + '\n';
    },
    headers.join(',') + '\n'
  );

  const blob = new Blob([csvString], { type: 'text/csv' });

  // Step 4: Create a download link
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}.csv`;

  // Step 5: Trigger the download
  document.body.appendChild(link); // Append link to the body
  link.click();

  // Step 6: Clean up
  document.body.removeChild(link); // Remove the link
  URL.revokeObjectURL(url); // Release object URL
}
