// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts,import_extension=none"
// @generated from file recommender/meta.proto (package recommenderpb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ExpiringInstsRequest, ExpiringInstsResponse, InstMetaRequest, InstMetaResponse, OmniSearchRequest, OmniSearchResponse, ReachByBooleanExpressionRequest, ReachByBooleanExpressionResponse, SegmentsMetaRequest, SegmentsMetaResponse, SuggestByTextRequest, SuggestSegmentsResp, SummaryRequest, SummaryResponse } from "./meta_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service recommenderpb.Meta
 */
export const Meta = {
  typeName: "recommenderpb.Meta",
  methods: {
    /**
     * @generated from rpc recommenderpb.Meta.Inst
     */
    inst: {
      name: "Inst",
      I: InstMetaRequest,
      O: InstMetaResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc recommenderpb.Meta.OmniSearch
     */
    omniSearch: {
      name: "OmniSearch",
      I: OmniSearchRequest,
      O: OmniSearchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc recommenderpb.Meta.Segments
     */
    segments: {
      name: "Segments",
      I: SegmentsMetaRequest,
      O: SegmentsMetaResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc recommenderpb.Meta.ReachByBooleanExpression
     */
    reachByBooleanExpression: {
      name: "ReachByBooleanExpression",
      I: ReachByBooleanExpressionRequest,
      O: ReachByBooleanExpressionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc recommenderpb.Meta.SuggestByText
     */
    suggestByText: {
      name: "SuggestByText",
      I: SuggestByTextRequest,
      O: SuggestSegmentsResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc recommenderpb.Meta.SummaryName
     */
    summaryName: {
      name: "SummaryName",
      I: SummaryRequest,
      O: SummaryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc recommenderpb.Meta.ExpiringInsts
     */
    expiringInsts: {
      name: "ExpiringInsts",
      I: ExpiringInstsRequest,
      O: ExpiringInstsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

