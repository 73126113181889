import { Timestamp } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import { atom } from 'jotai';
import withClient from '../../proto/with_client';
import { Meta } from '../../protos/recommender/meta_connect';
import { ExpiringInst } from '../../protos/recommender/meta_pb';

const client = withClient(Meta);
export const expiringLoadingAtom = atom<boolean>(false);

const threeDaysFromNow = new Date();
threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 7);
export const expiringBeforeAtom = atom<Date>(threeDaysFromNow);
export const expiringMinimumImpressionsAtom = atom<number>(1);

export const expiringInstsSearchAtom = atom<string>('');
export const expiringInstsIsPoliticalAtom = atom<boolean>(false);

export const allExpiringInstsAtom = atom<ExpiringInst[]>([]);

export enum SortBy {
  Name = 'name',
  DeliveredImpressions = 'deliveredImpressions',
  BookedImpressions = 'bookedImpressions',
  ExpiresIn = 'expiresIn',
  Reach = 'reach',
  Reach10 = 'reach10',
  Reach25 = 'reach25',
  Reach50 = 'reach50',
  Reach75 = 'reach75',
  Reach100 = 'reach100'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export const sortByAtom = atom<SortBy>(SortBy.DeliveredImpressions);
export const sortOrderAtom = atom<SortOrder>(SortOrder.Desc);

export const sortedExpiringInstsAtom = atom<ExpiringInst[]>((get) => {
  const insts = get(filteredExpiringInstsAtom);

  switch (get(sortByAtom)) {
    case SortBy.Name:
      insts.sort((a, b) => a.name.localeCompare(b.name));
      break;
    case SortBy.ExpiresIn:
      insts.sort(
        (a, b) => Number(a.end?.seconds ?? BigInt(0)) - Number(b.end?.seconds ?? BigInt(0))
      );
      break;
    case SortBy.BookedImpressions:
      insts.sort((a, b) => a.bookedImpressions - b.bookedImpressions);
      break;
    case SortBy.DeliveredImpressions:
      insts.sort(
        (a, b) =>
          a.deliveredImpressions / a.bookedImpressions -
          b.deliveredImpressions / b.bookedImpressions
      );
      break;
    case SortBy.Reach:
      insts.sort(
        (a, b) =>
          (a.predictions.find((p) => p.additionalImpressions === 0)?.reach ?? 0) -
          (b.predictions.find((p) => p.additionalImpressions === 0)?.reach ?? 0)
      );
      break;
    case SortBy.Reach10:
      insts.sort(
        (a, b) =>
          (a.predictions.find((p) => parseFloat(p.additionalImpressions.toFixed(2)) === 0.1)
            ?.reach ?? 0) -
          (b.predictions.find((p) => parseFloat(p.additionalImpressions.toFixed(2)) === 0.1)
            ?.reach ?? 0)
      );
      break;
    case SortBy.Reach25:
      insts.sort(
        (a, b) =>
          (a.predictions.find((p) => p.additionalImpressions === 0.25)?.reach ?? 0) -
          (b.predictions.find((p) => p.additionalImpressions === 0.25)?.reach ?? 0)
      );
      break;
    case SortBy.Reach50:
      insts.sort(
        (a, b) =>
          (a.predictions.find((p) => p.additionalImpressions === 0.5)?.reach ?? 0) -
          (b.predictions.find((p) => p.additionalImpressions === 0.5)?.reach ?? 0)
      );
      break;
    case SortBy.Reach75:
      insts.sort(
        (a, b) =>
          (a.predictions.find((p) => p.additionalImpressions === 0.75)?.reach ?? 0) -
          (b.predictions.find((p) => p.additionalImpressions === 0.75)?.reach ?? 0)
      );
      break;
    case SortBy.Reach100:
      insts.sort(
        (a, b) =>
          (a.predictions.find((p) => p.additionalImpressions === 1)?.reach ?? 0) -
          (b.predictions.find((p) => p.additionalImpressions === 1)?.reach ?? 0)
      );
      break;
  }

  if (get(sortOrderAtom) === SortOrder.Desc) {
    insts.reverse();
  }

  return insts;
});

const filteredExpiringInstsAtom = atom<ExpiringInst[]>((get) => {
  const expiringInsts = get(allExpiringInstsAtom);
  const query = get(expiringInstsSearchAtom).toLowerCase().trim();
  if (!query) return expiringInsts;
  return expiringInsts.filter(
    (c) =>
      c.advertiser.toLowerCase().includes(query) ||
      c.agency.toLowerCase().includes(query) ||
      c.station.toLowerCase().includes(query) ||
      c.clientName.toLowerCase().includes(query) ||
      c.name.toLowerCase().includes(query) ||
      c.id.toString().includes(query)
  );
});

export const expiringInstsAtom = atom(null, async (get, set) => {
  const expiringBefore = get(expiringBeforeAtom);
  const endsBefore = Timestamp.fromDate(expiringBefore);
  const isPolitical = get(expiringInstsIsPoliticalAtom);
  const minimumImpressions = get(expiringMinimumImpressionsAtom);

  try {
    set(expiringLoadingAtom, true);
    const resp = await client.expiringInsts({ endsBefore, isPolitical, minimumImpressions });

    set(allExpiringInstsAtom, resp.insts);
  } catch (e) {
    if (e instanceof ConnectError) {
      if (e.message.includes('cancelled duplicate request')) return;
    }
    console.warn(e);
  } finally {
    set(expiringLoadingAtom, false);
  }
});
