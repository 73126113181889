import { useAtom } from 'jotai';
import styled from 'styled-components';
import { expiringInstsSearchAtom } from '../../state/expiring_insts';

const Styled = styled.input`
  border-radius: 20px;
`;

function debounce<T extends (...args: any[]) => void>(func: T, wait: number): T {
  let timeout: NodeJS.Timeout;
  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  } as T;
}

const ExpiringInstsFilter: React.FC = () => {
  const [, setValue] = useAtom(expiringInstsSearchAtom);

  const debouncedSetValue = debounce(setValue, 300);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    debouncedSetValue(event.target.value);

  return (
    <Styled
      type="text"
      autoFocus={true}
      onChange={handleChange}
      placeholder="Filter by line name, ID, or org"
    />
  );
};

export default ExpiringInstsFilter;
