import React, { useState } from 'react';
import styled from 'styled-components';
import { ExpiringInst } from '../../../protos/recommender/meta_pb';
import TableCell from './TableCell';
import Tooltip from './Tooltip';

const Tag = styled.small`
  display: block;
  font-size: 1rem;
  font-weight: bold;
`;

const InstNameCell: React.FC<{ inst: ExpiringInst }> = ({ inst }) => {
  const [tooltip, setTooltip] = useState<boolean>(false);

  const orgs = [
    'hive',
    'clientName',
    'advertiser',
    'agency',
    'stationGroup',
    'station',
    'ssp',
    'pubGroup',
    'manu',
    'dmp',
    'dsp'
  ];

  return (
    <TableCell onMouseEnter={(e) => setTooltip(true)} onMouseLeave={() => setTooltip(false)}>
      {inst.name}
      <small>{inst.id}</small>
      {tooltip && (
        <Tooltip $left="-1rem">
          {orgs.map((org) => (
            <OrgTag key={org} name={org as keyof ExpiringInst} inst={inst} />
          ))}
        </Tooltip>
      )}
    </TableCell>
  );
};

const OrgTag: React.FC<{ name: keyof ExpiringInst; inst: ExpiringInst }> = ({ name, inst }) => {
  if (!inst[name]) return null;
  const display = name.replace(/([A-Z])/g, ' $1');

  return (
    <Tag>
      <strong>{display}</strong>
      {inst[name]?.toString()}
    </Tag>
  );
};

export default InstNameCell;
