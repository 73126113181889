import { atom } from 'jotai';
import { instIdAtom, wipExprAtom } from '../../Inst/state/inst_meta';
import withClient from '../../proto/with_client';
import { Meta } from '../../protos/recommender/meta_connect';
import { segmentByIdAtom } from './segments';

const client = withClient(Meta);

export const loadingAtom = atom<boolean>(false);

export const addSegmentsByTextAtom = atom(
  null,
  async (
    get,
    set,
    text: string,
    existingPredictedIds: string[],
    currentRunExistingSelectedIds: string[],
    limit: number
  ) => {
    set(loadingAtom, true);

    try {
      const instId = get(instIdAtom);
      const expr = get(wipExprAtom);
      const resp = await client.suggestByText({
        instId,
        expr,
        text,
        limit,
        existingPredictedIds,
        currentRunExistingSelectedIds
      });

      const byId = get(segmentByIdAtom);
      resp.segments.forEach((s) => (byId[s.id] = s));
      set(segmentByIdAtom, byId);
      return resp.segments.map((s) => s.id);
    } catch (e) {
      console.warn(e);
    } finally {
      set(loadingAtom, false);
    }
  }
);
