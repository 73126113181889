import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AudienceDashboard from './routes/AudienceDashboard';
import AudienceMatchmaker from './routes/AudienceMatchmaker';
import './styles/App.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AudienceMatchmaker />
  },
  {
    path: 'dashboard',
    element: <AudienceDashboard />
  }
]);

const App = () => <RouterProvider router={router} />;
export default App;
