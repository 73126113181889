import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const ModalWrapper = styled.div<{ $pinRight: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: ${(props) => (props.$pinRight ? 'right' : 'center')};
  align-items: center;
  z-index: 3;
`;

const ModalContent = styled.div<{ $pinRight: boolean }>`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 2000px;
  width: ${(props) => (props.$pinRight ? '50%' : '80%')};
  overflow-y: scroll;
  max-height: ${(props) => (props.$pinRight ? '100%' : '80%')};
  height: ${(props) => (props.$pinRight ? '100%' : 'auto')};
`;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  pinRight?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, pinRight }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose();
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalWrapper $pinRight={pinRight ?? false} onClick={onClose}>
      <ModalContent $pinRight={pinRight ?? false} onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalWrapper>,
    document.getElementById('root') as Element
  );
};

export default Modal;
