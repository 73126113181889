// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts,import_extension=none"
// @generated from file recommender/predict.proto (package recommenderpb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BooleanExpressionRequest, InstPrediction, InstRequest } from "./predict_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service recommenderpb.Predict
 */
export const Predict = {
  typeName: "recommenderpb.Predict",
  methods: {
    /**
     * @generated from rpc recommenderpb.Predict.ByInst
     */
    byInst: {
      name: "ByInst",
      I: InstRequest,
      O: InstPrediction,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc recommenderpb.Predict.ByBooleanExpression
     */
    byBooleanExpression: {
      name: "ByBooleanExpression",
      I: BooleanExpressionRequest,
      O: InstPrediction,
      kind: MethodKind.Unary,
    },
  }
} as const;

