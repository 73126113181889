import ExpiringInsts from '../Inst/ui/ExpiringInsts/ExpiringInsts';
import AuthenticatedLayout from '../Users/ui/AuthenticatedLayout';
import GridLayout from '../components/Layout/Grid';
import GridItem from '../components/Layout/GridItem';

const AudienceDashboard = () => {
  return (
    <AuthenticatedLayout>
      <GridLayout>
        <GridItem $columns={4}>
          <ExpiringInsts />
        </GridItem>
      </GridLayout>
    </AuthenticatedLayout>
  );
};

export default AudienceDashboard;
