import { useAtom } from 'jotai';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { instLoadingAtom, instNameAtom } from '../Inst/state/inst_meta';
import InstHistory from '../Inst/ui/InstCard';
import InstSearchGrid from '../Inst/ui/InstSearchGrid';
import BooleanExpressionEntrypoint from '../Segments/ui/BooleanExpression/BooleanExpressionEntrypoint';
import DetailAllSwaps from '../Segments/ui/DetailAllSwaps';
import AuthenticatedLayout from '../Users/ui/AuthenticatedLayout';
import Card from '../components/Card';
import Grid from '../components/Layout/Grid';
import GridItem from '../components/Layout/GridItem';
import Loading from '../components/Loading/LoadingDots';

function AudienceMatchmaker() {
  const [instName] = useAtom(instNameAtom);
  const [loading] = useAtom(instLoadingAtom);

  if (!instName) {
    return (
      <AuthenticatedLayout>
        <InstSearchGrid />
        {loading && <Loading />}
      </AuthenticatedLayout>
    );
  }

  return (
    <AuthenticatedLayout>
      <InstSearchGrid />
      <Grid>
        <GridItem $columns={2}>
          <Card
            type="text"
            title={
              <h3>
                Segments {loading && <Loading />} {!loading && <DetailAllSwaps />}
              </h3>
            }>
            <div style={{ width: '100%' }}>
              <BooleanExpressionEntrypoint />
            </div>
          </Card>
        </GridItem>
        <GridItem $columns={2}>
          <InstHistory />
        </GridItem>
      </Grid>
    </AuthenticatedLayout>
  );
}

export default AudienceMatchmaker;
